<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Document Verification Portal</h1>
      <div class="flex justify-end items-end">
        <button
          class="bg-blue-600 py-3 px-6 rounded-md text-white"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center justify-between">
        <div class="w-1/2">
          <input
            type="text"
            class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
            name=""
            placeholder="Search Business Name"
            id=""
            v-model="search"
          />
          <button
            @click="searchBusinessName"
            class="inline-block uppercase shadow ml-3 bg-blue-800 text-white text-xs hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            Search
          </button>
          <button
            @click="clearSearchBusinessName"
            class="inline-block uppercase shadow ml-3 bg-red-800 text-white text-xs hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            Clear
          </button>
        </div>
        <div class="my-10 flex justify-end items-end">
          <button
            @click="fetchDocumentVerificationByStatus('ALL')"
            :class="
              statusSort === 'ALL'
                ? 'bg-green-800 text-white text-xs'
                : 'bg-gray-300 text-black text-xs'
            "
            class="block uppercase shadow ml-3 hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            All
          </button>
          <button
            @click="fetchDocumentVerificationByStatus('VERIFIED')"
            :class="
              statusSort === 'VERIFIED'
                ? 'bg-green-800 text-white text-xs'
                : 'bg-gray-300 text-black text-xs'
            "
            class="block uppercase shadow ml-3 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-black text-xs py-3 px-8 rounded hover:text-white"
          >
            Verified
          </button>
          <button
            @click="fetchDocumentVerificationByStatus('PENDING')"
            :class="
              statusSort === 'PENDING'
                ? 'bg-green-800 text-white text-xs'
                : 'bg-gray-300 text-black text-xs'
            "
            class="block uppercase shadow ml-3 hover:bg-blue-700 hover:text-white focus:shadow-outline focus:outline-none text-black text-xs py-3 px-8 rounded"
          >
            Pending
          </button>
          <button
            @click="fetchDocumentVerificationByStatus('FAILED')"
            :class="
              statusSort === 'FAILED'
                ? 'bg-green-800 text-white text-xs'
                : 'bg-gray-300 text-black text-xs'
            "
            class="block uppercase shadow ml-3 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-black text-xs py-3 px-8 rounded hover:text-white"
          >
            Rejected
          </button>
        </div>
      </div>
      <div class="flex bg-blue-50 p-5 mt-10">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startDate"
          />
        </div>
        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.endDate"
          />
        </div>
        <div class="flex mt-5">
          <button
            @click="fetchDatedRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Reset
          </button>
          <!-- <button
              @click="downloadDatedCSV"
              class="block uppercase shadow bg-blue-800 hover:bg-blue-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
            >
              Download
            </button> -->
        </div>
      </div>
      <div class="mt-4 flex bg-gray-50 p-5">
        <div class="block mt-4 mr-3" v-if="filterByParams == 'documentType'">
          <multiselect
            :taggable="false"
            v-model="filterByDocumentType"
            :options="documentTypes"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
            placeholder="Filter by Document Type"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single hidden"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.toString() }}</span
              ></template
            >
          </multiselect>
        </div>
        <div class="block">
          <button
            class="inline-block bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="filterFunc"
            :disabled="filterByDocumentType == ''"
          >
            Filter
          </button>
        </div>
        <div class="block">
          <button
            class="inline-block drop-shadow-2xl bg-yellow-200 text-dark py-2 px-3 mt-4 rounded-md mr-2"
            @click="clearFilterFunc"
            :disabled="filterByDocumentType == ''"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <div class="mt-14" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="15"
              style="background: #dee6f2"
            >
              Merchant Information
            </th>
          </tr>
          <tr style="background: #fff">
            <th class="text-sm border whitespace-nowrap text-left px-8 py-2">
              Merchant Id
            </th>
            <th class="text-sm border whitespace-nowrap text-left px-8 py-2">
              Business Name
            </th>
            <th class="text-sm border whitespace-nowrap text-left px-8 py-2">
              Selfie Upload
            </th>
            <th class="text-sm border whitespace-nowrap text-left px-8 py-2">
              ID Type
            </th>
            <th class="text-sm border whitespace-nowrap text-left px-8 py-2">
              Verification Status
            </th>
          </tr>
          <tr
            v-for="(document, i) in filterDocuments"
            :key="i"
            @click="
              $router.push({
                name: 'ViewDocumentVerification',
                params: {
                  id: document._id,
                },
              })
            "
            class="cursor-pointer"
          >
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.businessId }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.businessName }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              TRUE
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.documentType }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.status }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="filterDocuments.length > 99"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Loading from "./general-components/Loading.vue";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      moment,
      documents: null,
      isLoading: false,
      search: "",
      documentFilterKey: "all",
      statusSort: "ALL",
      isDisabled: true,
      currentPage: 1,
      dateData: null,
      dateFilterActive: false,
      searchActive: false,
      statusFilterActive: false,
      activeStatus: "ALL",
      filterByParams: "documentType",
      filterByDocumentType: "",
      documentTypes: ["nin", "bvn", "vin", "passport", "frsc"],
      documentFilterActive: false,
    };
  },
  components: {
    Loading,
    Multiselect,
  },
  computed: {
    ...mapGetters(["GET_VERIFIED_DOCUMENTS"]),
    filterDocuments() {
      let k = this.GET_VERIFIED_DOCUMENTS.data.items.filter(
        (document) =>
          document.businessName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          document.businessId
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          document.verificationReference
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          document.documentType
            .toLowerCase()
            .includes(this.search.toLowerCase())
      );

      if (this.statusSort !== "ALL") {
        return [...k].filter((x) => {
          return x.status === this.statusSort;
        });
      } else {
        return k;
      }
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchDocumentVerification();
  },
  methods: {
    ...mapActions(["FETCH_ALL_DOCUMENT_VERIFICATIONS"]),
    async fetchDocumentVerification() {
      this.currentPage = 1;
      let payload = {
        page: this.currentPage,
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        documentFilterActive: this.documentFilterActive,
        filterByDocumentType: this.filterByDocumentType,
      };
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch(
          "FETCH_ALL_DOCUMENT_VERIFICATIONS",
          payload
        );
        if (response.status) {
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async filterFunc() {
      this.currentPage = 1;
      this.documentFilterActive = true;
      let payload = {
        page: this.currentPage,
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        documentFilterActive: this.documentFilterActive,
        filterByDocumentType: this.filterByDocumentType,
      };
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch(
          "FETCH_ALL_DOCUMENT_VERIFICATIONS",
          payload
        );
        if (response.status) {
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchDocumentVerificationByStatus(status) {
      this.statusFilterActive = true;
      this.statusSort = status;
      this.activeStatus = status;
      this.status = status;
      this.currentPage = 1;
      let payload = {
        page: this.currentPage,
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        documentFilterActive: this.documentFilterActive,
        filterByDocumentType: this.filterByDocumentType,
      };
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch(
          "FETCH_ALL_DOCUMENT_VERIFICATIONS",
          payload
        );
        if (response.status) {
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearDateFilter() {
      this.dateFilterActive = false;
      this.currentPage = 1;
      this.resetDate();
      this.fetchDocumentVerification();
    },
    resetDate() {
      const d = new Date();
      this.dateData = {
        startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
        endDate: new Date().toJSON().split("T")[0],
        creationDate: new Date().toJSON().split("T")[0],
        startTime: "00:00",
        endTime: this.moment(d).format("HH:mm"),
      };
    },
    async fetchDatedRecords() {
      this.dateFilterActive = true;
      this.currentPage = 1;
      if (
        this.dateData.startDate > this.dateData.endDate ||
        this.dateData.startDate === this.dateData.endDate
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.currentPage = 1;

        let payload = {
          page: this.currentPage,
          statusFilterActive: this.statusFilterActive,
          dateFilterActive: this.dateFilterActive,
          status: this.activeStatus,
          date: this.dateData,
          searchActive: this.searchActive,
          search: this.search,
          documentFilterActive: this.documentFilterActive,
          filterByDocumentType: this.filterByDocumentType,
        };
        try {
          this.isLoading = true;
          let res = await this.$store.dispatch(
            "FETCH_ALL_DOCUMENT_VERIFICATIONS",
            payload
          );

          this.isLoading = false;
          if (response.status) {
            this.isLoading = false;
          }
        } catch (error) {}
      }
    },
    async downloadCSV() {
      this.isLoading = true;
      let payload = {
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        documentFilterActive: this.documentFilterActive,
        filterByDocumentType: this.filterByDocumentType,
        source: "DOCUMENT",
      };
      this.isLoading = true;

      try {
        let response = await this.$store.dispatch(
          "VERIFICATION_DOWNLOAD_CSV",
          payload
        );
        this.isLoading = false;
        if (response.status) {
          window.open(response.data, "_blank");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = {
        page: currPage - 1,
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        documentFilterActive: this.documentFilterActive,
        filterByDocumentType: this.filterByDocumentType,
      };

      let res = await this.$store.dispatch(
        "FETCH_ALL_DOCUMENT_VERIFICATIONS",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = {
        page: currPage + 1,
        statusFilterActive: this.statusFilterActive,
        dateFilterActive: this.dateFilterActive,
        status: this.activeStatus,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
        documentFilterActive: this.documentFilterActive,
        filterByDocumentType: this.filterByDocumentType,
      };

      let res = await this.$store.dispatch(
        "FETCH_ALL_DOCUMENT_VERIFICATIONS",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },

    async searchBusinessName() {
      if (this.search !== "") {
        this.isLoading = true;
        this.searchActive = true;
        this.currentPage = 1;
        let payload = {
          page: this.currentPage,
          statusFilterActive: this.statusFilterActive,
          dateFilterActive: this.dateFilterActive,
          status: this.activeStatus,
          date: this.dateData,
          searchActive: this.searchActive,
          search: this.search,
          documentFilterActive: this.documentFilterActive,
          filterByDocumentType: this.filterByDocumentType,
        };
        let res = await this.$store.dispatch(
          "FETCH_ALL_DOCUMENT_VERIFICATIONS",
          payload
        );
        if (!res.status) {
          alert(res.message);
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      }
    },

    async clearSearchBusinessName() {
      this.search = "";
      this.searchActive = false;
      this.fetchDocumentVerification();
      this.currentPage = 1;
    },

    async clearFilterFunc() {
      this.documentFilterActive = false;
      this.filterByDocumentType = "";
      this.fetchDocumentVerification();
      this.currentPage = 1;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
